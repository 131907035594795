import React, { ReactNode } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  margin: 70px 0;

  & > * {
    width: 50%;
  }

  p {
    color: var(--secondary-label-color);
    font-size: 20px;
  }

  @media (max-width: 1000px) {
    p {
      font-size: 1em;
    }
  }

  @media (max-width: 650px) {
    flex-direction: column;
    margin: 0 0 20px;

    & > * {
      width: 100%;
    }
  }
`;

const Body = styled.div`
  @media (max-width: 650px) {
    order: 1;
  }
`;

const Image = styled.div`
  img, video {
    width: 100%;
    padding: 25px;
  }

  img.left, video.left {
    padding-right: 0;
  }

  img.right, video.right {
    padding-left: 0;
  }

  @media (max-width: 650px) {
    img, video {
      padding-right: 25px !important;
      padding-left: 0;
    }
  }
`;

type HomeStepProps = {
  contentLeft?: boolean;
  image: ReactNode;
  title: ReactNode;
  body: ReactNode;
};

const HomeStep: React.FC<HomeStepProps> = ({ 
  contentLeft = false, 
  image, 
  title, 
  body 
}) => {

  const content = (
    <Body>
      {title}
      {body}
    </Body>
  );

  return (
    <Container>
      {contentLeft && content}

      <Image>
        {image}
      </Image>

      {!contentLeft && content}
    </Container>
  );
};

export default HomeStep;