import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';

const Card = styled.div`
  text-align: center;

  img {
    display: block;
    margin: 0 auto;
    width: 100%;
    height: 100px;
    object-fit: contain;

    @media (max-width: 650px) {
      height: 100px;
    }
  }

  h4 {
    margin: 30px 0 10px;

    @media (max-width: 767px) {
      margin: 15px 0 5px;
    }
  }

  p {
    color: var(--secondary-label-color);
    font-size: 20px;
  }

  @media (max-width: 1000px) {
    p {
      font-size: 1em;
    }
  }
`;

const Icon = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 30px;
  margin: 0 auto;
  background-color: ${props => props.color || "#ee504f"};
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    font-size: 30px;
  }
`;

type HomeStepProps = {
  image?: any;
  icon?: string;
  color?: string;
  title: string;
  body?: string;
};

const HomeStep: React.FC<HomeStepProps> = ({ image, icon, color, title, body }) => {
  return (
    <Card>
      {image && 
        <img src={image} alt={title} />
      }

      {icon &&
        <Icon color={color}>
          <span className="no-select">{icon}</span>
        </Icon>
      }

      <h4>{title}</h4>
      
      {body && 
        <p>{body}</p>
      }
    </Card>
  );
};

export default HomeStep;